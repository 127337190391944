/**
 * Global element IDs used in the application.
 *
 * These are used to identify elements in the DOM for accessibility purposes.
 */
export const GLOBAL_ELEMENT_IDS = {
  app: "app",
  portal: "portal",
  main: "main",
  mainContent: "main-content",
  floatingPromptBar: "floating-prompt-bar",
  LeftDrawer: "left-drawer",
  rightDrawer: "right-drawer-overlay",
  modal: "modal-overlay",
  accountContextMenu: "account-context-menu",
  accountContextMenuMobile: "account-context-menu-mobile",
} as const;
